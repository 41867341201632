*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flex-container-v{
  display: flex;
  flex-direction: column;



}


.flex-container-h{
  padding-top: 5rem;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: 3rem;
  background-color: #e2e8f0;
}

.project-title{
  
  font-size: 1.25rem;
  color: #0f172a;
  text-align: center;
  font-weight: 500;
    background-color: #e2e8f0;
      letter-spacing: 4px;
    

}
.pj-span-js{
  border-bottom: 2px solid #f0db51;
}
.pj-span-react{
  border-bottom: 2px solid #60dbfb;
}

@media only screen and (min-width: 1300px) {
    .flex-container-h{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      
           }
  .flex-container-v{
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

}



/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/