body{

    
}
.flex-container-h-Header{
  display: flex;
  flex-direction: row;
  column-gap:1rem;
  row-gap: 40rem;
  padding-top: 3rem;
  padding-bottom: 4.6rem;
  background-color: white;
    
  
}
.project-title{
  font-size: 3.05rem;
  line-height: 1.3;
 
}



.text-part{
  display: flex;
  justify-content: start;
  flex-direction: column;

  align-items: start;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  
  
  

}
.page-title{
   
  font-size: 3.05rem;
  color: #0d0d0d;
  letter-spacing: .2rem;
  width: 70%;
  margin-bottom: 2rem ;
 
  
}



.description{
  font-size: 1.25rem;
  color: #0f172a;
  margin-bottom: 2rem ;
  line-height: 1.8rem;
}

.js-span{
  border-bottom: 2px solid #f0db51;
  letter-spacing: 4px;
  font-weight: bold;
  font-style: italic;
}


.react-span{
  border-bottom: 2px solid #60dbfb;
  letter-spacing: 4px;
  font-weight: bold;
  font-style: italic;
  
}
.page-button{
  background-color: #f0db51;
  color: #0d0d0d;
  font-size: 1.25rem;
  height: 50px;
  cursor: pointer;
  
 
  padding-top : 1rem;
  padding-bottom : 2.35rem;
  padding-left : 4rem;
  padding-right : 4rem;
  border-radius: .5rem;
  letter-spacing: 2px;
  font-weight: 500;
}




.header-image-container{

display: none;
height: auto;
width: 100%;
padding-top: 2.5rem;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .page-title{
   

  width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .page-title{
   

  width: 95%;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .header-image-container{
        display: block;
        
    }
    .flex-container-h-Header{
        width: 94%;
        margin-left: auto;
        margin-right: auto;
    }

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
        .flex-container-h-Header{
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
        .flex-container-h-Header{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
        .flex-container-h-Header{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        
    }

}