.project-container{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 1rem;
    padding-bottom: 4rem;
    justify-content: flex-start;
    justify-items: flex-start;
    

  
    


}



.img-container{
    
    width: 96%;
    max-height: 500px;
    
  
    margin-left: auto;
    margin-right: auto;
   
    background-color: white;
    cursor: pointer;

 
    border-radius: .3rem;
   
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
}
.mini-webpage-container{
    width: 100%;
    max-height:450x;
    
}
.mini-webpage-image{
    
    width: 100%;
    border-top-right-radius: .3rem;
    border-top-left-radius: .3rem;
    height: auto;
   
}
.img-container:hover{
    transform: scale(1.05);
}

.img-container { 
transition: 0.3s ease-in-out all;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .img-container{

    max-width: 375px;

}

.mini-webpage-container{
     max-width: 375px;
}
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .img-container{
    height: auto;
    
    width: 45%;
    cursor: pointer;
    }
}

.project-card-title{
    padding-top: .7rem;

    text-align: center;
    font-size: 1.25rem;
    color: #0f172a;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-family:  "Inconsolata",monospace;;
    height:50px;
    max-height: 50px;
    min-height: 50px;
  
   
}


.card-a-link{
 text-decoration: none;
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

 
    .project-container{
        width: 94%;
        margin-left: auto;
        margin-right: auto;
    }

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1172px) {

    .img-container{
    height: auto;
    width: 32%;
    cursor: pointer;
}
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
        .project-container{
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }

     .img-container{
        height:auto ;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .project-container{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        
           }
     .img-container{
        height:auto;
        
    }

}


 